.react-autocomplete-box{
    border: 1px solid rgba(0,0,0,0.15);
    border-radius:9px;
    padding: 10px;
}
.react-autocomplete-input {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.15);
    bottom: auto;
    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
    display: block;
    font-size: 14px;
    list-style: none;
    padding: 9px 0 9px 0;
    border-radius:9px;
    position: absolute;
    text-align: left;
    z-index: 20000;
 }
 
 .react-autocomplete-input > li {
   cursor: pointer;
   padding: 7px;
   min-width: 100px;
 }
 
 .react-autocomplete-input > li.active {
   background-color: #FF4842;
   font-size: 15px;
   font-weight:bold;
 }
 
 