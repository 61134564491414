.modeClass:hover{
    fill:#DFF2FF;
    background-color: #DFF2FF;
    stroke:#000000;
    cursor: pointer;
}
.speedBtn:hover {
    transform: scale(1.1);
    cursor: pointer;
    fill:#DFF2FF;
    background-color: #DFF2FF;
    -webkit-transform: scale(1.2);
    -webkit-transition: -webkit-transform 0.2s ease-out;
    transition: -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}
.tempBtn:hover{
    cursor: pointer;
    fill:#DFF2FF;
    background-color: #DFF2FF;
}
.node:hover {
    transform: scale(1.1);
    cursor: pointer;
    -webkit-transform: scale(1.1);
    -webkit-transition: -webkit-transform 0.5s ease-out;
    transition: -webkit-transform 0.5s ease-out;
    transition: transform 0.5s ease-out;
    transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}