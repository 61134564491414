#idstation{fill:#FFFFFF;background-color: #FFFFFF;}
#group_sun{transform-origin: "center";transform-box: "fill-box";}
#helice{transform-origin: "center";transform-box: "fill-box";}
.st0{fill:#8ADCC6;}
.st1{fill:none;stroke:#000000;strokeWidth:2;stroke-miterlimit:10;}
.st2{fillRule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#012038;strokeWidth:1.5;}
.st3{fill:none;stroke:#012038;strokeWidth:1.5;}
.st4{fill:none;stroke:#FFFFFF;strokeWidth:1.5;strokeLinecap:square;}
.st5{fill:none;stroke:#012038;strokeWidth:1.5;strokeLinejoin:round;}
.st6{fill:none;stroke:#012038;strokeLinecap:round;}
.st7{fill:none;stroke:#012038;strokeWidth:1.7325;strokeLinecap:round;strokeLinejoin:round;}
.st8{fill:none;stroke:#012038;strokeWidth:1.7325;strokeLinecap:square;}
.st9{fill:none;stroke:#012038;strokeWidth:1.5;strokeLinecap:square;}
.st10{fill:none;stroke:#012038;strokeWidth:1.5;strokeLinecap:round;strokeLinejoin:round;}
.st11{fill:none;stroke:#012038;strokeWidth:1.8;}
.st12{fill:none;stroke:#012038;strokeWidth:1.8;strokeLinecap:round;strokeLinejoin:round;}
.st13{fillRule:evenodd;clip-rule:evenodd;fill:#012038;stroke:#012038;strokeWidth:0.6;strokeLinejoin:round;}
.st14{fill:none;stroke:#000000;strokeWidth:3;stroke-miterlimit:10;}
.st15{fill:#E69850;}
.st16{fill:#8ADCC5;}
.st17{fill:#89D7BF;}
.st18{fillRule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#FFFFFF;stroke-miterlimit:10;}
.st19{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
.st20{fill:#F5DE42;}
.st21{fill:none;stroke:#FFCC00;strokeWidth:3;stroke-miterlimit:10;stroke-dasharray:6;}
.st22{font-family:'Montserrat-SemiBold';}
.st23{font-size:16px;fill:#737679;}
.st24{fillRule:evenodd;clip-rule:evenodd;fill:#012038;stroke:#012038;strokeLinejoin:round;}
.st25{font-size:3.6457px;}
.st26{fill:#012038;}
.st27{fill:none;stroke:#012038;strokeWidth:1.5;strokeLinecap:round;}
.st28{fill:none;stroke:#012038;stroke-miterlimit:10;}
.st29{clip-path:url(#SVGID_00000075137583810187391200000017325091510476169121_);}
.st30{clip-path:url(#SVGID_00000040548402513065157530000013093675034017241000_);}
.st31{fill:#FFFFFF;}
.st32{font-size:12.6998px;}
.st33{display:none;}
.st34{display:inline;fill:#FFFFFF;}
.st35{fill:none;stroke:#00A99D;strokeWidth:2;stroke-miterlimit:10;}
