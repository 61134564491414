
.node:hover {
  transform: scale(1.1);
  cursor: pointer;
  -webkit-transform: scale(1.1);
  -webkit-transition: -webkit-transform 0.5s ease-out;
  transition: -webkit-transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}
.nodeOther:hover {
  transform: scale(1.1) translate(-60px, -60px);
  cursor: pointer;
  -webkit-transform: scale(1.1) translate(-60px, -60px);
  -webkit-transition: -webkit-transform 0.5s ease-out;
  transition: -webkit-transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}